.container {
	height: 100vh;
}

.title-container {
	top: 0;
	left: 0;
	right: 0;
	z-index: 1;
	height: 4rem;
	display: flex;
	position: fixed;
	align-items: center;
	background: rgb(5, 150, 105);
}

.create-container {
	z-index: 1;
	top: 4rem;
	left: 0;
	right: 0;
	height: 5rem;
	padding: 20px;
	display: flex;
	position: fixed;
	background: #fff;
	align-items: center;
}

.task-container {
	padding-top: 9rem;
}

.task {
	display: flex;
	margin: 0 20px;
	font-size: 16px;
	padding: 12px 20px;
	align-items: center;
	border-radius: 5px;
}
.task:hover {
	background: rgba(5, 150, 105, 0.1);
}

.task__no {
	color: #111111;
	margin-right: 5px;
}

.task__title {
	flex: 1;
	margin-right: 5px;
	word-break: break-all;
}

.task__btn {
	width: 18px;
	height: 18px;
	opacity: 0.5;
}

.task__btn:hover {
	opacity: 1;
}


.input {
	width: 100%;
	font-size: 15px;
	padding: 12px 16px;
	border-radius: 5px;
	border: 1px solid #e0e0e0;
}

.input:focus {
	border: 1px solid rgb(5, 150, 105);
	box-shadow: 0px 0px 1px 1px rgb(5, 150, 105);
}

.input::-moz-placeholder {
	color: #919191;
	font-size: 15px;
}
.input:-ms-input-placeholder {
	color: #919191;
	font-size: 15px;
}
.input::placeholder {
	color: #919191;
	font-size: 15px;
}
.input:disabled {
	background: #f8f8f8;
}



.loader--lg {
	width: 60px;
	height: 60px;
	display: block;
	border-radius: 50%;
	box-sizing: border-box;
	border-top: 7px solid rgb(5, 150, 105);
	border-right: 7px solid rgb(5, 150, 105);
	border-bottom: 7px solid transparent;
	animation: spin 0.8s linear infinite;
}

.loader--sm {
	width: 25px;
	height: 25px;
	display: block;
	border-radius: 50%;
	box-sizing: border-box;
	border-top: 4px solid rgb(5, 150, 105);
	border-right: 4px solid rgb(5, 150, 105);
	border-bottom: 4px solid transparent;
	-webkit-animation: spin 0.8s linear infinite;
	animation: spin 0.8s linear infinite;
}

.loader--xs {
	width: 20px;
	height: 20px;
	display: block;
	border-radius: 50%;
	box-sizing: border-box;
	border-top: 4px solid rgb(5, 150, 105);
	border-right: 4px solid rgb(5, 150, 105);
	border-bottom: 4px solid transparent;
	-webkit-animation: spin 0.8s linear infinite;
	animation: spin 0.8s linear infinite;
}

.btn {
	flex-shrink: 0;
	display: flex;
	cursor: pointer;
	font-size: 15px;
	font-weight: 500;
	padding: 12px 16px;
	align-items: center;
	border-radius: 5px;
}

.btn--primary {
	color: #fff;
	background: rgb(5, 150, 105);
}

.btn--primary__loader {
	width: 15px;
	height: 15px;
	margin: 0 5px;
	display: block;
	border-radius: 50%;
	box-sizing: border-box;
	border-top: 3px solid #fff;
	border-right: 3px solid #fff;
	border-bottom: 3px solid transparent;
	-webkit-animation: spin 0.8s linear infinite;
	animation: spin 0.8s linear infinite;
}

.btn--primary:disabled {
	background: rgba(5, 150, 105, 0.7);
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
